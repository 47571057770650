export const c2bSellerQuestionaireEvents = {
    "getCarPrice": {
        "event": "get_car_price",
        "eventCategory": "Seller_questions",
        "eventAction": "get_car_price_click"
    },
    "getMake": {
        "event": "make",
        "eventCategory": "Seller_questions",
        "eventAction": "make_click"
    },
    "getModel": {
        "event": "model",
        "eventCategory": "Seller_questions",
        "eventAction": "model_click"
    },
    "getYear": {
        "event": "year",
        "eventCategory": "Seller_questions",
        "eventAction": "year_click"
    },
    "getNumberProceed": {
        "event": "number_proceed",
        "eventCategory": "Seller_questions",
        "eventAction": "number_proceed_click"
    },
    "getOdometerSubmit": {
        "event": "odometer_submit",
        "eventCategory": "Seller_questions",
        "eventAction": "odometer_submit_click"
    },
    "getCarSpecs": {
        "event": "car_specs",
        "eventCategory": "Seller_questions",
        "eventAction": "car_specs_click"
    },
    "getCancel": {
        "event": "cancel",
        "eventCategory": "Seller_questions",
        "eventAction": "cancel_click"
    },
    "getMoreCarBrands": {
        "event": "more_car_brands",
        "eventCategory": "Seller_questions",
        "eventAction": "more_car_brands_click"
    },
    "getSellWhatsappBookmark": {
        "event": "sell_whatsapp_bookmark",
        "eventCategory": "Seller_questions",
        "eventAction": "sell_whatsapp_bookmark_click"
    },
    "getSellEditQuestions": {
        "event": "sell_edit_questions",
        "eventCategory": "Seller_questions",
        "eventAction": "sell_edit_questions_click"
    },
    "getSellWhatsappChat": {
        "event": "sell_whatsapp_chat",
        "eventCategory": "Seller_questions",
        "eventAction": "sell_whatsapp_chat_click"
    },
    "getSellRequestCall": {
        "event": "sell_request_call",
        "eventCategory": "Seller_questions",
        "eventAction": "sell_request_call_click"
    },
    "sellGsFailPage": {
        "event": "sell_gs_fail_page",
        "eventCategory": "Seller_questions",
        "eventAction": "sell_gs_fail_page_load"
    },
    "otherInfoSubmitClick": {
        "event": "sell_other_info",
        "eventCategory": "Seller_questions",
        "eventAction": "sell_other_info_click"
    },
    "backButtonPopupOpen": {
        "event": "sell_back_button_popup",
        "eventCategory": "Seller_questions",
        "eventAction": "back_button_popup_open"
    },
    "backButtonPopupClose": {
        "event": "sell_back_button_popup",
        "eventCategory": "Seller_questions",
        "eventAction": "back_button_popup_close"
    },
    "backButtonContinue": {
        "event": "sell_continue",
        "eventCategory": "Seller_questions",
        "eventAction": "sell_continue_click"
    },
    "backButtonTalkToAgent": {
        "event": "sell_talktoagent",
        "eventCategory": "Seller_questions",
        "eventAction": "sell_talktoagent_click"
    },
    "backButtonBackToLast": {
        "event": "sell_back_to_last",
        "eventCategory": "Seller_questions",
        "eventAction": "sell_back_to_last_click"
    },
    "expectedCarPriceClick": {
        "event": "sell_expected_car_price",
        "eventCategory": "Seller_questions",
        "eventAction": "sell_expected_car_price_click"
    },
    "abSellerShowPrice": {
        "event": "custom_event-ab-seller-show-car-price",
        "eventCategory": "Homepage",
        "eventAction": "ab-seller-show-car-price"
    }
};
